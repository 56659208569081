export const headerLinks = [
  {
    name: 'Home',
    href: '/',
  },
  {
    name: 'About Us',
    href: '/about-us',
  },
  {
    name: 'Services',
    href: '/services',
  },
  {
    name: 'Drive With Us',
    href: '/drive-with-us',
  },
  {
    name: 'Contact Us',
    href: '/contact',
  },
];
