import { company } from '@components/config/company/Details';
import Link from 'next/link';
import React from 'react';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import { headerLinks } from '@components/config/headerLinks';
import HouseIcon from '@mui/icons-material/House';
import MailIcon from '@mui/icons-material/Mail';
import PhoneIcon from '@mui/icons-material/Phone';
import PublicIcon from '@mui/icons-material/Public';
import Image from 'next/image';

function Footer() {
  const {
    Facebook,
    Instagram,
    Twitter,
    LinkedIn,
    address,
    company_email,
    tel_number,
    website,
    Copyright,
  } = company;
  return (
    <>
      <div className="bg-secondaryLight py-20 md:px-10 lg:px-20 text-white">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10 container">
          <div>
            <div className="mt-5 flex gap-3 ">
              <Link passHref href={Facebook}>
                <a
                  className="border hover:text-black hover:bg-primary/20 rounded-md w-10 h-10 vhcenter"
                  target="_blank"
                >
                  <Image
                    width="9"
                    height="16"
                    src="/assets/images/facebook.png"
                    alt="facebook"
                  />
                </a>
              </Link>
              <Link passHref href={Twitter}>
                <a
                  className="border text-white rounded-md hover:bg-primary/20  w-10 h-10 vhcenter"
                  target="_blank"
                >
                  <Image
                    width="17"
                    height="14"
                    src="/assets/images/twitter.png"
                    alt="twitter"
                  />
                </a>
              </Link>
              <Link passHref href={LinkedIn}>
                <a
                  className="border text-white rounded-md hover:bg-primary/20  w-10 h-10 vhcenter"
                  target="_blank"
                >
                  <Image
                    width="15"
                    height="14"
                    src="/assets/images/linkedin.png"
                    alt="linkedin"
                  />
                </a>
              </Link>
              <Link passHref href={Instagram}>
                <a
                  className="border text-white rounded-md hover:bg-primary/20  w-10 h-10 vhcenter"
                  target="_blank"
                >
                  <Image
                    width="15"
                    height="15"
                    src="/assets/images/instagram.png"
                    alt="instagram"
                  />
                </a>
              </Link>
            </div>
          </div>

          <div className="flex flex-col sm:items-center">
            <div>
              <div className="text-xl">Quick Links</div>
              <div className="mt-5 space-y-5 text-gray-400">
                {headerLinks.map((link, index) => (
                  <div
                    className="flex gap-3 items-center hover:bg-primary hover:text-white"
                    key={link.name}
                  >
                    <Link passHref href={link.href}>
                      {link.name}
                    </Link>
                  </div>
                ))}
                <div>
                  <Link passHref href="/app-download">
                    <a className="hover:bg-primary hover:text-white">
                      Download Our App
                    </a>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="flex lg:items-center flex-col">
            <div>
              <div className="text-xl">Contact Info</div>
              <div className="mt-5 space-y-5 text-gray-400">
                <div className="flex items-center gap-3">
                  <HouseIcon className="text-xl" />
                  {Object.values(address).toString().replaceAll(',', ', ')}
                </div>
                <div>
                  <Link href={`mailto:${company_email}`}>
                    <a className='hover:underline'>
                      <div className="flex items-center gap-3">
                        <MailIcon className="text-xl" />
                        {company_email}
                      </div>
                    </a>
                  </Link>
                </div>
                <div>
                  <Link href={`tel:${tel_number}`}>
                    <a className='hover:underline'>
                      <div className="flex items-center gap-3">
                        <PhoneIcon className="text-xl" />
                        <div>{tel_number}</div>
                      </div>
                    </a>
                  </Link>
                </div>
                <div className="flex items-center gap-3">
                  <PublicIcon className="text-xl" />
                  {website}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-secondary flex items-center md:flex-row flex-col justify-between px-10  py-5 text-gray-400">
        <div className="">
          <div className="text-sm md:text-left text-center md:text-base">{Copyright}</div>
        </div>
        <div className='flex md:flex-row flex-col md:mt-0 mt-5 items-center gap-4'>
          <div className='text-sm md:text-left text-center'>Website developed by</div>
          <Link href={'https://xiots.io/'}>
            <a target={'_blank'}>
              <Image src={'/assets/images/Xiotslogo.webp'} alt='Xiots Logo' className='rounded' height={30} width={70} />
            </a>
          </Link>
        </div>
      </div>
    </>
  );
}

export default Footer;
