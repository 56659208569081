export const company = {
  seotitle:
    'Shipley Central Taxis - Trusted Taxi Service in Bradford for Over 40 Years',
  seodescription: `Shipley Central Taxis, serving Shipley, Saltaire, Nabwood, Bingley, Cottingley, Frizinghall, Heaton, Baildon, Charlestown, Guiseley, Otley, Bradford Forster Square, Bradford Town Center and more for over 40 years. We offer airport transfers, business transportation, family travel, and school runs. Proudly the official transportation provider for ASDA Shipley and NHS Bradford (BRI and St Lukes ).`,
  name: 'Shipley Central Taxis',
  address: {
    building: 'The Kiosk',
    street: '1 Kirkgate',
    town: 'Shipley',
    postcode: 'BD18 3QP',
  },
  cookies_name: '',
  apptitle: 'Shipley Central Taxis App',
  apple_store_id: 'app-id=id1171116619',
  google_store_id: 'app-id=com.autocab.taxibooker.shipleycentraltaxis',
  apple_store_url: 'https://apps.apple.com/gb/app/shipley-taxis/id1171116619',
  google_store_url:
    'https://play.google.com/store/apps/details?id=com.autocab.taxibooker.shipleycentraltaxis.shipley&hl=en_GB',
  company_email: 'shipleycentraltaxis@gmail.com',
  tel_number: '01274 595959',
  dpo_email: 'shipleycentraltaxis@gmail.com',
  dpo: '',
  vat_number: '-',
  company_number: '',
  website: 'www.shipleycentral.com',
  url: 'https://www.shipleycentral.com',
  dpo_reg_number: '-',
  updated_on: '17/09/2023',
  Copyright: 'Copyright 2023 | Shipley Central Taxis | All Rights Reserved',
  logo_url: 'https://shipley-central-web.vercel.app/assets/images/logo.png',
  logo_alt: 'Shipley Central Taxis Logo',
  width: '180',
  height: '70',
  width_mobile: '180',
  height_mobile: '70',
  contactus_task_url: 'https://',
  Facebook: 'https://www.facebook.com/shipleycentraltaxis/?locale=en_GB',
  Instagram: 'https://www.instagram.com/shipleycentral/?hl=en',
  Twitter:
    'https://twitter.com/i/flow/login?redirect_after_login=%2Fshipleytaxis',
  LinkedIn: 'https://www.linkedin.com/company/shipley-central-taxis/about/',
};
// Check List - make sure update below files before making this website live
// 1- robots_txt: 'update robots.txt file domain details
// 1-   robots_txt: 'update robots.txt file domain details
// 2-  regenarate manifest.json file and icons and put in public folder
