import { createTheme } from '@mui/material/styles';

// Create a theme instance.
const theme = createTheme({
  palette: {
    primary: {
      main: '#FECE01',
    },
    secondary: {
      main: '#1C2B39',
      light: '#374B5D',
    },
    success: {
      main: '#34D399',
    },
  },
});

export default theme;
