import React from 'react';
import HouseIcon from '@mui/icons-material/House';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import { headerLinks } from '@components/config/headerLinks';
import Link from 'next/link';
import { company } from '@components/config/company/Details';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import CancelIcon from '@mui/icons-material/Cancel';
import Image from 'next/image';
import { useRouter } from 'next/router';

function Header() {
  const { company_email, address, tel_number } = company;
  const router = useRouter();
  const [drawer, setDrawer] = React.useState(false);
  const { pathname } = router;

  return (
    <div className="">
      <div className="grid grid-cols-1 lg:grid-cols-6 lg:gap-5 items-center justify-between container py-10 space-y-5">
        <div className="relative col-span-2 flex items-center justify-between">
          <Image
            src="/assets/images/logo.png"
            width={280}
            height={60}
            alt="logo"
            className="w-44 sm:w-auto lg:w-auto"
          />
          <div className="lg:hidden block">
            <MenuIcon
              onClick={() => setDrawer(true)}
              color="secondary"
              className="lg:hidden cursor-pointer text-5xl"
            />
          </div>
          <Drawer
            anchor={'right'}
            open={drawer}
            onClose={() => setDrawer(false)}
          >
            <div className="bg-white h-screen ">
              <div className="flex justify-between p-4">
                <CancelIcon
                  onClick={() => setDrawer(false)}
                  className="cursor-pointer text-3xl ml-auto"
                />
              </div>
              <div className="flex flex-col items-center justify-center">
                {headerLinks.map((item, index) => (
                  <div key={index} className="w-full">
                    <Link passHref href={item.href}>
                      <a
                        className={` h-full w-full py-3 px-10 flex items-center font-bold text-lg justify-start ${pathname === item.href ? 'bg-primary' : 'bg-white'
                          }`}
                      >
                        {item.name}
                      </a>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </Drawer>
        </div>
        <div className="col-span-4">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 md:flex md:justify-between">
            <div className="flex items-center justify-start md:justify-center gap-3">
              <div className="border border-primary rounded-full p-1 md:w-12 md:h-12 vhcenter">
                <HouseIcon color="primary" className="md:text-3xl" />
              </div>
              <div>
                <div className="text-lg font-bold text-primary">Address</div>
                <div className="text-sm md:text-lg">
                  {Object.values(address).toString().replaceAll(',', ', ')}
                </div>
              </div>
            </div>

            <div className="flex items-center justify-start md:justify-center gap-3">
              <div className="border border-primary rounded-full p-1 md:w-12 md:h-12 vhcenter">
                <EmailIcon color="primary" className="md:text-3xl" />
              </div>
              <div>
                <div className="text-lg font-bold text-primary">Email Us</div>
                <Link href={`mailto:${company_email}`}>
                  <a className="text-sm md:text-lg">{company_email}</a>
                </Link>
              </div>
            </div>

            <div className="flex items-center justify-start md:justify-center gap-3">
              <div className="border border-primary rounded-full p-1 md:w-12 md:h-12 vhcenter block lg:hidden">
                <PhoneIcon color="primary" className="md:text-3xl" />
              </div>
              <div className="w-16 hidden lg:block">
                <div className="absolute bg-secondaryLight/90 rounded-[30px] rounded-tl-none rounded-tr-none w-16 h-[115px] top-0 flex items-end justify-center pb-2">
                  <div className="border-white/20 border-2 bg-secondaryLight rounded-full w-12 h-12 vhcenter relative z-10">
                    <PhoneIcon className="text-3xl text-white" />
                  </div>
                </div>
              </div>
              <div>
                <div className="text-sm md:text-lg font-medium">
                  <a href={`tel:${tel_number}`}>{tel_number}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-secondary text-white hidden md:block">
        <div className="container flex justify-between">
          <ul className="flex items-center gap-10 py-6">
            {headerLinks.map((link, index) => (
              <div
                key={index}
                className={`hover:text-primary ${pathname === link.href && 'text-primary'
                  }`}
              >
                <Link passHref href={link.href}>
                  {link.name}
                </Link>
              </div>
            ))}
          </ul>
          <Link passHref href="/">
            <a className="bg-primary flex items-center px-10 text-lg gap-2 cursor-pointer">
              Book Now
              <ArrowCircleRightOutlinedIcon />
            </a>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Header;
